import * as React from "react";
import { navigate } from "gatsby";
import yn from "yn";

import makeStyles from '@mui/styles/makeStyles';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

import { ISODateToLocaleString, base64Encode } from "../../utils";
import { useIOProducts } from "../../hooks/use-io-products";

import CopyCodeSnippet from "../CopyCodeSnippet";
import DownloadLink from "../DownloadLink";
import OrderError from "./OrderError";
import Feedback from './Feedback';

import lulc_9_class from "../../images/jersey-9class.png";
import lulc_14_class from "../../images/jersey-14class.png";

const MOD_API_URL = process.env.GATSBY_MOD_API_URL;
const DISABLE_ESRI_TRANSFER = yn(process.env.GATSBY_DISABLE_ESRI_TRANSFER);

const useStyles = makeStyles((theme) => {
  return {
    downloadRoot: {
      '& p': {
        marginBottom: theme.spacing(1)
      },
      '& h6': {
        fontWeight: 'normal',
      }
    },
    previewImage: {
      width: '100%',
      minHeight: 450,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      border: 'none',
    },
    tabs: {
      marginBottom: theme.spacing(2)
    },
    tabPanel: {
      marginBottom: theme.spacing(2),
      '& ul': {
        padding: 0,
        margin: '0 0 0 20px',
      },
    },
    
    hr: {
      marginBottom: theme.spacing(2),
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{background: props.background || '#F8F9FA'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function OrderAsset(props) {
  const { asset, order_id, asset_key, product } = props;
  const file_parts = asset.href.split('/');
  const file_name = file_parts[file_parts.length-1];
  const file_extension = file_name.split('.').pop();
  const metrics = asset['io:class_percentages'];

  let asset_name = asset.title;
  if (product.id === "lulc-9-class-annual") {
    asset_name = "Annual Map"
  } else if (asset.title.indexOf(' - ') > 0) {
    asset_name = asset.title.split(' - ')[1].replace('Sample', 'Ordered');
  }
  return (
    file_extension === 'tif' ? (<>
      <Typography>{asset_name} (
        <DownloadLink href={`${MOD_API_URL}/assets/${order_id}/${asset_key}.png`}>PNG</DownloadLink>,
        <DownloadLink href={`${MOD_API_URL}/assets/${order_id}/${asset_key}.tif`}>Geotiff</DownloadLink>
        { metrics ? (<>,
        <DownloadLink href={`${MOD_API_URL}/assets/${order_id}/${asset_key}.csv`}>Metrics</DownloadLink>
        </>): ''}
      )</Typography>
    </>
    ) : (null)
  )
}

export default function DownloadOrder(props) {
  const classes = useStyles();
  const { IO_PRODUCT_LOOKUP } = useIOProducts();
  
  const [order, setOrder] = React.useState({assets: []});
  const [loadError, setLoadError] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const changeTab = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect( loadOrder => {
    if (window.history.state?.order) {
      setOrder(window.history.state.order);
    } else {
      fetch(`${MOD_API_URL}/order/${props.id}`)
        .then(async(response) => {
          if(response.ok) {
            return await response.json()
          } else {
            if (response.status === 425) {
              console.debug('too early to get order')
              navigate(`/order/${props.id}/processing`, {state: {disableScrollUpdate: true}})
            } else {
              throw new Error(`Unable to get order #${props.id}`)
            }
          }
        })
        .then(data => {
          data.status = data.properties["order:status"];
          if(data.assets) {
            Object.keys(data.assets).forEach((key) => {
              if (data.assets[key]["roles"].includes("data")) {
                let az_url = new URL(data.assets[key].url);
                data.assets[key].url_no_sas = `${az_url.protocol}//${az_url.hostname}${az_url.pathname}`;
                data.assets[key].path = az_url.pathname;
              }
            });
            data['esri_transfer_blob'] = base64Encode(`${MOD_API_URL}/esri/${props.id}/transfer.json`);
            setOrder(data);
          } else {
            throw new Error(`Order #${props.id} does not have assets`)
          }
        })
        .catch(e => {
          console.error(e)
          setLoadError(`Sorry, we were unable to load order #${props.id}`)
        })
    }
  }, [props.id])

  React.useEffect( redirectCompleteOrder => {
    if (order.status && !(order.status === "DONE" || order.status === "succeeded")) {
      navigate(`/order/${order.id}/processing`, {state: {disableScrollUpdate: true}})
    }
  }, [order])

  if (loadError) {
    return (
      <OrderError message={loadError}/>
    )
  }

  const PRODUCT = IO_PRODUCT_LOOKUP[order.properties?.["io:product_id"]] || {};
  const preview_asset_key = order.properties?.["io:primary_asset"] || Object.keys(order.assets)[0];  // fallback to first asset if 'io:primary_asset' prop not found
  const preview_asset = order.assets[preview_asset_key];

  return (
    order.status ? (<div className={classes.downloadRoot}>
      <Container>
        <Grid container spacing={3} justifyContent="center" mb={2}>
          <Grid item lg={9}>
            <Typography variant="h4" component="h2" textAlign="center">
              Order #{order.id}
            </Typography>
            <Typography variant="h6" textAlign="center">
              {ISODateToLocaleString(preview_asset.start_datetime)} - {ISODateToLocaleString(preview_asset.end_datetime)} over {order.properties.title}
            </Typography>
          </Grid>
        </Grid>

        <div className="stepContent">
          <Grid>
            <div className={classes.previewImage}
              style={{backgroundImage: `url("${MOD_API_URL}/assets/${order.id}/${preview_asset_key}.png")`}}
            />
            <Typography>Download to your device or copy to cloud storage.</Typography>
          </Grid>

          <Grid>
            <Tabs value={tab} onChange={changeTab} aria-label="data download options"
              indicatorColor="primary" textColor="primary" className={classes.tabs}
            >
              <Tab label="File Downloads" />
              <Tab label="ArcGIS Image Dedicated Services" />
              <Tab label="Cloud Storage Transfer" />
            </Tabs>

            <TabPanel value={tab} index={0} className={classes.tabPanel}>
              <Typography>
                Your {PRODUCT.title} over {order.properties.title} are ready for download.
              </Typography>
              <Box mb={1}>
                <Button variant="contained" color="primary" href={`${MOD_API_URL}/assets/${order.id}.zip`} download={`${order.id}_assets.zip`}>
                  <ArchiveIcon style={{marginRight: 5}} /> Download All Files
                </Button>
              </Box>
              <Box>
                <Typography>
                  Zip file download includes:
                </Typography>
                <ul>
                  {Object.keys(order.assets).filter(key => !order.assets[key].roles.includes("tiles")).map((key) => (
                    <li key={key}><OrderAsset asset={order.assets[key]} order_id={order.id} asset_key={key} product={PRODUCT} /></li>
                  ))}
                  <li><Typography>Area of Interest (<DownloadLink href={`${MOD_API_URL}/assets/${order.id}/aoi.geojson`} filename={`${order.id}.geojson`}>GeoJSON</DownloadLink>)</Typography></li>
                </ul>
              </Box>

            </TabPanel>

            <TabPanel value={tab} index={1} className={classes.tabPanel}>
              <Typography variant="h6" mt={1}>ESRI ArcGIS Online</Typography>
              <Typography variant="body2">
                IO Monitor supports the direct transfer of output to Esri ArcGIS Online using Esri Image Dedicated Services.
              </Typography>
              <Typography variant="body2">
                You must have a subscription to ArcGIS Image Dedicated Services to transfer maps to ArcGIS Online. Speak to your Esri administrator to learn more.
              </Typography>
              <Typography variant="body2">
                Transfer and processing via Image Dedicated Services can take one to several hours to complete.
              </Typography>
              <Button variant="contained" disabled={DISABLE_ESRI_TRANSFER}>
                <DriveFileMoveIcon style={{marginRight: 5}} />
                  <a href={`https://aid-1.img.arcgis.com/apps/transfer/index.html?url=${order.esri_transfer_blob}`} target="_blank" rel="noreferrer">{DISABLE_ESRI_TRANSFER ? "Under Maintenance" : "Begin Transfer"}</a>
              </Button>
            </TabPanel>

            <TabPanel value={tab} index={2} background="white" className={classes.tabPanel}>
              <Typography variant="h6">Azure</Typography>
              <Typography variant="body2">Follow these steps to transfer your {PRODUCT.title} to your own Azure account. For additional help, you may access <a href="https://learn.microsoft.com/en-us/azure/storage/common/storage-use-azcopy-blobs-copy#copy-a-blob" target="_blank" rel="noreferrer">Azure’s documentation site</a>.</Typography>
              {Object.keys(order.assets).map((key) =>
                <CopyCodeSnippet key={key} text={
                  `azcopy cp '${order.assets[key].url_no_sas}' 'https://<destination-storage-account-name>.blob.core.windows.net/<container-name>/?<destination-token>'`
                 } />
              )}

              <Typography variant="h6" mt={1}>AWS S3</Typography>
              <Typography variant="body2">Follow these steps to transfer your {PRODUCT.title} to your own Amazon account. For additional help, you may access <a href="https://awscli.amazonaws.com/v2/documentation/api/latest/reference/s3/cp.html#:~:text=Copying%20an%20S3%20object%20from%20one%20bucket%20to%20another" target="_blank" rel="noreferrer">Amazon's documentation site</a>.</Typography>
              {Object.keys(order.assets).map((key) =>
                <CopyCodeSnippet key={key} text={
                  `aws s3 cp 's3:/${order.assets[key].path}' 's3://<destination-bucket>/'`
                } />
              )}
              
            </TabPanel>
          </Grid>

          <Divider className={classes.hr} />
    
          {order?.properties.product_id === "lulc-9-class-annual" && (<>
            <Grid>
              <Typography variant="body1">
                <strong>Need even more insights?</strong><br/>
                Upgrade to our premium 14 class land cover maps to reveal even more detail. Choose from the most recent satellite imagery or specify a sub-annual date range for seasonal insights.
              </Typography>
            </Grid>

            <Grid item xs={12} textAlign={"center"}>
              <Typography variant="body2">
                <strong>9 class vs 14 class</strong>
              </Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{textAlign: "right"}}>
                <img src={lulc_9_class} alt="Example 9-class annual map "/>
              </Grid>
              <Grid item xs={6}>
                <img src={lulc_14_class} alt="Example 14-class sub-annual map"/>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign={"center"} mt={1} mb={2}>
              <Button variant="outlined"><a href="/order/">Order Upgraded Maps</a></Button>
            </Grid>
          </>)}

          <Grid>
            <Typography variant="body1">
              <a href="https://docs.impactobservatory.com/" target="_blank" rel="noreferrer">Read more</a> about using land use and land cover data to monitor change over time. 
            </Typography>

            <Typography variant="body1">
              <strong>Interested in more data?</strong><br/>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSd1oBFkzWM3iTtbDHITiWkWH1rx3WAWWYDCASaM-1QGqVynJQ/viewform" target="_blank" rel="noreferrer">
              Tell us more</a> about your monitoring needs in our Early Access Program and receive early access to products currently in development at Impact Observatory. 
            </Typography>
          </Grid>

          {/* <Grid container item xs={12} flexDirection={"row"} justifyContent={"center"}>
            <Button variant="outlined" sx={{mr: 1}}><a href="https://www.impactobservatory.com/maps_on_demand">IO Monitoring</a></Button>
            <Button variant="contained"><Link to="/order/">Create New Map</Link></Button>
          </Grid> */}
    </div>

    </Container>

    <Feedback dark={true} showRating={true} order={order} />
  </div>) : (
    loadError ? (
      <OrderError message={loadError}/>
    ) : (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    )
  )
  );
}
